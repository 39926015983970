import { deepMerge } from "grommet/utils";
import { grommet } from 'grommet/themes';

export const WK24_Theme = deepMerge(grommet,{
    global: {
        colors: {
            brand: "#9ECC84",
            aubergine:"#571C56",
            spaceLila:"#7649FE",
            green: 'brand',
            blue: '#42506e',
            pink: '#fb00be',
            darkgreen: '#839b76',
            active: 'secondary',
            "grey-1": "#ECE9E3",
            "grey-2": "#CECCC6",
            "grey-3": "#737069",
            "grey-4": "#52504C",
            /* End color definition */
            background: {
                dark: "aubergine",
                light: "spaceLila"
            },
            text: "brand",
            border:"white"

        },
        font: {
            color: 'brand',
            family: 'brandon_grotesquebold',
            size: '18px',
            height: '20px',
        },
        focus:{
            border:{
                color: "brand"
            }
        },
    },
    text:{
        custom: {
            size: '26px',
            height: '45px'
        },
    },

    button:{
        default: {
            color: "brand",
            border: {
                radius: '30px',
                color: 'spaceLila',
                width: '2px'
            },
            font:{
                size:'medium',
                color:'spaceLila',
            },
            background:{
                image: 'url(https://www.beatsinthewoods.com/images/weekender-2024/space-background.jpg)',
                size: 'cover',
                color: 'brand',
            },
            padding: {
                horizontal: '18px',
                vertical: '14px',
              },            
           
        },
        hover:{
            default:{
                border: {
                    width: '4px'
                },
                padding: {
                    horizontal: '18px',
                    vertical: '12px',
                  },  
            }
        }
    }

})