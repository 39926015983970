import logo from "./media/images/logonobg.png";
import huisregels from "./media/images/weekender-2024/huisregels.jpg"
import tijdtafel from "./media/images/weekender-2024/timetable.jpg"
import plattegrond from "./media/images/weekender-2024/plattegrond.jpg"
import programma from "./media/images/weekender-2024/programma.jpg"

import React, {Component} from 'react';
import { BitW } from './common/Theme.js';
import {Anchor, Box, Grommet, Image, Nav} from 'grommet';
import {Instagram, Moon, Sun, Ticket, Soundcloud} from "grommet-icons";
import {  Route,  Routes } from 'react-router-dom';
import Tikkie from './weekender2023/Tikkie.js';
import InviteYourFriend from './weekender2023/InviteYourFriend.js';
import OpenPage from './weekender2023/OpenPage.js';
import TikkieWithParam from './weekender2023/TikkieWithParam';
import InfoOnSite from './weekender-2024/InfoOnSite.js';
import NotFound from "./common/NotFound";
import ImageViewer from "./common/ImageViewer";
import TicketPortal from "./sjateau2024/TicketPortal.js";
import SD24Webshop from "./sjateau2024/SD24_Webshop.js";
import Weekender24 from "./weekender-2024/Weekender24.js";
import WK24Webshop from "./weekender-2024/WK24_Webshop.js";

class App extends Component{
  constructor(props) {
      super(props);
      this.state = {
          darkMode: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
          isLoading: false
      }
  }

  setDarkMode = (useDarkMode) => {
      this.setState({
          darkMode: useDarkMode
      });
  }

  render() {
      let themeIcon = <Moon size="medium" />
      if(this.state.darkMode){themeIcon = <Sun size="medium"/>}
      return (
          <Grommet 
          theme={BitW} 
          themeMode={this.state.darkMode ? "dark" : "light"}
          style={{minHeight: "100vh"}}>
              <Box align='end'>
                  <Nav direction="row" gap="small" style={{paddingTop:'5px'}} >
                    <Anchor href="/Weekender2024/Tickets/" target="_blank"
                        hoverIndicator="darkgreen"
                        icon={<Ticket size="medium"/>}
                        style={{padding: '5px'}}
                        color="brandInvert"> adsfsdf
                    </Anchor>
                    <Anchor href="https://soundcloud.com/beats-in-the-woods/" target="_blank"
                        hoverIndicator="darkgreen"
                        icon={<Soundcloud size="medium"/>}
                        style={{padding: '5px'}}
                        color="brandInvert">
                    </Anchor>
                    <Anchor href="https://www.instagram.com/beatsinthewoods/" target="_blank"
                        hoverIndicator="darkgreen"
                        color="brandInvert"
                        icon={<Instagram  size="medium"/>}
                        style={{padding: '5px'}}>
                    </Anchor>
                    <Anchor
                        hoverIndicator="darkgreen"
                        color="brandInvert"
                        icon={themeIcon}
                        style={{padding: '5px 15px 5px 5px'}}
                        onClick={() => {this.setDarkMode(!this.state.darkMode)}}
                    />
                  </Nav>
              </Box>
              <Routes>
                  <Route index element={
                      <Box height="90vh">
                          <Image
                              fit='contain'
                              src={logo}
                          />
                      </Box>
                  }/>
                  <Route path="SjateauDisceau"                      element={<TicketPortal/>}/>
                  <Route path="SjateauDisceau/:ticketcode"          element={<SD24Webshop/>}/>

                  <Route path="Weekender2023"                       element={<NotFound/>}/>
                  <Route path="Weekender2023/restant-tikkie"        element={<Tikkie  amount={170}/>}/>
                  <Route path="Weekender2023/volledig-tikkie"       element={<Tikkie amount={265}/>}/>
                  <Route path="Weekender2023/invite-your-friend"    element={<InviteYourFriend/>}/>
                  <Route path="Weekender2023/extra-nachtje"         element={<Tikkie amount={25} /> }/>
                  <Route path="Weekender2023/Tikkie/:amount"        element={<TikkieWithParam/> }/>
                  <Route path="Weekender2023/CarpoolLijst"          element={<OpenPage url={"https://docs.google.com/spreadsheets/d/13EXLM8hbv9t1AACBf0Z5PLdzJtKeii-Yc6ecTY0qETE/edit?usp=sharing"} /> }/>
                  <Route path="Weekender2023/Merchandise"           element={<OpenPage url={"https://docs.google.com/forms/d/e/1FAIpQLSejc3LhjMKujJgxkxehqo05KC2x_j35T0rBWA2DLQkJDtdwTw/viewform?usp=sf_link"} /> }/>
                  <Route path="Weekender2023/Dieetwensen"           element={<OpenPage url={"https://forms.gle/vdLe3oDu3iFL36168"} /> }   />
                  <Route path="Weekender2024/Diensten"              element={<OpenPage url={"https://signup.com/go/wOQqPUd"} /> }               />
                  <Route path="Weekender2023/Muziek"                element={<OpenPage url={"https://soundcloud.com/beats-in-the-woods/sets/beats-in-the-woods-2023?si=683f25e2615243f194387679bfb2b844&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"} /> } />
                  <Route path="Weekender2023/Glampen"               element={<OpenPage url={"https://forms.gle/aFxa8Uu5kNTJ6m4f7/"} /> }    />
                  <Route path="Weekender2024/Info"                  element={<InfoOnSite darkMode={this.state.darkMode} />}/>
                  <Route path="Weekender2024/Info/Huisregels"       element={<ImageViewer imageUrl={huisregels} />}/>
                  <Route path="Weekender2024/Info/Plattegrond"      element={<ImageViewer imageUrl={plattegrond} />}/>
                  <Route path="Weekender2024/Info/Programma"        element={<ImageViewer imageUrl={programma} />}/>
                  <Route path="Weekender2024/Info/Tijdtafel"        element={<ImageViewer imageUrl={tijdtafel} />}/>
                  <Route path="Weekender2023/Fotos"                 element={<OpenPage url={"https://photos.app.goo.gl/ufdpT83cLNGyzDXn8"} /> }    />
                  <Route path="Weekender2023/FinancieelResultaat"   element={<OpenPage url={"https://my.visme.co/view/90pn03p6-beats-in-the-woods-weekender-2023"} /> }    />
                  <Route path="Weekender2024/Feedback"              element={<OpenPage url={"https://forms.gle/52RaLSxjmN1JvqYK6"} /> }    />

                  <Route path="Weekender2024/:approveDecline/:userId" element={<Weekender24/>}/>
                  <Route path="Weekender2024/Tickets" element={<WK24Webshop/>}/>

                  <Route path="*" element={<NotFound/>}/>
              </Routes>
          </Grommet>
      );
  }
}


export default App;
